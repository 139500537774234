/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.small-paginator {
    .mat-mdc-paginator-container {
        padding: 0 !important;
        @apply w-full #{'!important'};
        @apply flex #{'!important'};
        @apply justify-between #{'!important'};
    }

    .mat-mdc-paginator-page-size-label {
        @apply text-xs #{'!important'};
    }

    .mat-mdc-paginator-range-label {
        @apply text-xs #{'!important'};
        @apply my-0 #{'!important'};
        @apply mx-2 #{'!important'};
    }

    .mat-mdc-paginator-range-actions {
        @apply mr-2 #{'!important'};
    }

    .mat-mdc-icon-button {
        width: 24px !important;
    }
}

//Leaflet styles
.leaflet-div-icon {
    width: 0px !important;
    height: 0px !important;
}

.leaflet-bottom {
    z-index: 999 !important;
}

//MDC: fix search-by-input (filters)
.search-by-input {
    .mat-mdc-form-field-flex {
        @apply px-0 #{'!important'};
    }
    .mat-mdc-form-field-icon-suffix {
        @apply bg-gray-200 #{'!important'};
        width: 46px;
        min-height: 48px;

        button {
            @apply pr-3;
            margin-right: 0 !important;
        }
    }
}

//MDC: fix configuration-input
.configuration-input {
    .mat-mdc-form-field-flex {
        @apply px-0 #{'!important'};
    }
    .mat-mdc-form-field-icon-suffix {
        border-left: 1px solid rgb(203 213 225 / var(--tw-border-opacity)) !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 56px;
        padding: 0 20px;

        button {
            @apply pr-3;
            margin-right: 0 !important;
        }
    }
}

// MDC: fix mat form field
.mat-mdc-form-field-infix {
    width: auto !important;
}
.mat-mdc-form-field-icon-prefix {
    @apply ml-3 #{'!important'};
}

// MDC: fix dropdown filters
.mat-mdc-menu-panel .mat-mdc-checkbox, .mat-mdc-checkbox, .mdc-checkbox {

}
.mat-mdc-checkbox .mdc-label {
    line-height: normal;
}

//MDC: fix mat-chip
.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label,
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.mat-mdc-chip-action-label {
    @apply flex #{'!important'};
    @apply items-center #{'!important'};
}

//MDC: fix tags
.tag-actions > * {
    padding-left: 2px !important;
    padding-right: 0 !important;;
}

app-tags .mat-mdc-form-field-flex {
    padding: 0 0 0 10px !important;
}

app-tags .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    box-shadow: none !important;
    border-width: 0 !important;
    background-color: transparent !important;
}
app-tags .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    min-height: 25px;
}

// MDC: fix tabs
.mat-mdc-tab-group .mat-mdc-tab-body-content {
    padding: 0;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container {
    margin: 0;
}

// MDC: fix dialog
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
}

// MDC: fix accordion and expansion-panel
.mat-accordion .mat-expansion-panel, .mat-accordion .mat-expansion-panel:hover {
    box-shadow: none !important;
    border-radius: 0 !important;
}
.extension-panel-no-paddings .mat-expansion-panel-body {
    padding: 0 !important;
}
.mat-accordion .mat-expansion-panel.mat-expanded, .mat-accordion .mat-expansion-panel:hover {
    box-shadow: none !important;
}

// MDC: change checkbox/radio smaller (as was)
html {
    --mdc-checkbox-state-layer-size: 20px;
}

.mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 20px;
}

.mat-mdc-icon-button.no-ripple .mat-mdc-button-persistent-ripple::before {
    content: "";
    opacity: 0;
    background-color: inherit !important;
}
// MDC: fix calendar with month view
.mat-calendar-table { width: 100%; }

// remove background in mat-stepper
.mat-step-header {
    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover:not([aria-disabled]),
    &:hover[aria-disabled='false'] {
        background-color: transparent !important;
    }
}

.fuse-horizontal-navigation-item {
    width: 100%;
}

.mat-mdc-option.mdc-list-item--disabled {
    pointer-events: inherit !important;
}

gridster {
    background-color: transparent !important;

    .gridster-row {
        border-top: 1px dashed theme('colors.gray[300]') !important;
        border-bottom: 1px dashed theme('colors.gray[300]') !important;
    }

    .gridster-column {
        border-left: 1px dashed theme('colors.gray[300]') !important;
        border-right: 1px dashed theme('colors.gray[300]') !important;
    }

    gridster-item {
        @apply rounded-2xl #{'!important'};
        @apply overflow-hidden #{'!important'};
    }
}


.header-less-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
    display: none;
}

.select-search {
    .mat-mdc-pseudo-checkbox {
        display: none;
    }

    input {
        color: #333 !important;
        width: 100%;
    }
}

.select-search * {
    opacity: 1 !important;
}