/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* FullCalendar */
//@import '~@fullcalendar/core/main.css';
//@import '~@fullcalendar/daygrid/main.css';
//@import '~@fullcalendar/timegrid/main.css';
//@import '~@fullcalendar/list/main.css';

/* Perfect scrollbar */
@import '../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import '../../node_modules/quill/dist/quill.snow.css';

/* ngx-datatable */
@import '../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'datatable.scss';
