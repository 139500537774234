.info-box {
  background: rgba(39, 192, 200, 0.05);
}


/* BADGES */
.count-badge {
  @apply hidden sm:block text-sm w-5 h-5 leading-5 text-secondary text-center -mt-1.5 bg-slate-200 rounded-full;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
}

.index-badge {
  @apply text-sm w-5 h-5 leading-5 text-white text-center bg-teal-400 rounded-full;
}


/* BUTTONS */
.show-more-btn {
  @apply w-22 flex items-center justify-center border-2 border-teal-400 text-teal-400 text-xs rounded-full bg-card;
}


.no-items-yet {
  @apply pb-4 text-center text-blue-500;
}



/* CARDS */
.teal-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border-2 rounded border-teal-400 relative;

  .card-actions-bottom {
    @apply bg-card px-1.5 inline-block absolute;
    bottom: -9px;
    left: calc(50% - 44px);
  }
}

.slate-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border-2 rounded relative;

  border: 2px dashed #bcc0c5 !important;

  &.active {
    @apply bg-blue-50;

    .bg-slate-100 { background-color: white !important; }

    .text-slate-500 { color: #333 !important; }
  }
}

.neutral-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border rounded border-neutral-300 relative;

  .card-actions-bottom {
    @apply bg-card px-1.5 inline-block absolute;
    bottom: -9px;
    left: calc(50% - 44px);
  }
}

/* STEPPERS AND CHAINS */
.pretty-stepper {
  @apply flex items-center w-full;

  .step {
    @apply flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700 cursor-pointer;

    span {
      @apply flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0;
    }
  }

  .last-step {
    @apply flex items-center cursor-pointer;

    span {
      @apply flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0;
    }
  }

  .active {
    @apply text-blue-600 after:border-blue-100 dark:after:border-blue-800;

    span {
      @apply bg-blue-100 dark:bg-blue-800;
    }
  }
}

.pretty-stepper-header {
  @apply text-center text-secondary mb-4 font-bold text-xl;
}

.pretty-chain {
  @apply flex items-center w-full;

  .step {
    @apply relative flex items-center after:content-[''] after:w-9 after:h-1 after:border-b after:border-dashed after:border-slate-500 after:inline-block dark:after:border-gray-700 cursor-pointer;

    div:first-child {
      @apply flex space-x-2 px-4 items-center justify-center h-9 bg-slate-200 text-slate-500 rounded-full dark:bg-gray-700 shrink-0;
    }
  }

  .last-step {
    @apply relative flex items-center cursor-pointer;

    div:first-child {
      @apply flex space-x-2 items-center justify-center w-48 h-9 bg-gray-200 rounded-full dark:bg-gray-700 shrink-0;
    }
  }

  .step+.step:before, .step+.last-step:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #64748B;
    margin-top: 3px;
  }

  .hint {
    position: absolute;
    bottom: -16px;
  }
}



/* Pill-Buttons */
.pill-teal-blue {
  @apply flex space-x-2 items-center justify-center rounded-full w-50 h-9 bg-teal-blue-500 text-white #{'!important'};

  mat-icon { @apply text-white #{'!important'}; }
}

.pill-lime {
  @apply flex space-x-2 items-center justify-center rounded-full w-50 h-9 bg-[#95CB5B] text-white #{'!important'};

  mat-icon { @apply text-white #{'!important'}; }
}
